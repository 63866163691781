import DOMPurify from 'dompurify';
class LoadMore {
	constructor() {
		this.loadMoreBtn = document.getElementById('show_more_casinos');
		this.init();
	}

	init() {
		if (!this.loadMoreBtn) {
			return;
		}
		this.offset = 20;
		this.allCasinos = DOMPurify.sanitize(parseInt(this.loadMoreBtn.dataset.all));
		this.loadMoreBtn.addEventListener('click', (e) => this.handleLoadMorePosts(e));
	}

	handleLoadMorePosts(e) {
		e.preventDefault();
		e.stopPropagation();

		const showMore = this.loadMoreBtn;
		const nonce = showMore.dataset.nonce;
		const data = {
			'action': 'load_more_casinos',
			'nonce': nonce,
			'all_casinos': this.allCasinos,
			'offset': this.offset
		};
		const self = this;
		$.ajax({
			url: localizedScript.url,
			type: 'POST',
			data: data,
			success: (response) => {
				console.log(response);
				$(response).insertBefore(showMore);
				this.offset += 10;
				if (this.offset >= this.allCasinos) {
					$(showMore).hide();
				}
				const remainingCount = self.allCasinos - self.offset;
				showMore.textContent = `Show more (${remainingCount})`;
				console.log(this.offset);
				console.log(this.allCasinos);
			}
		});
	}
}

new LoadMore();
