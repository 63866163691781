
$('.comment-cat-js').on('click',function(){
	$('#comments_pagination').remove();
	const active = 'active';
	const $that = $(this);
	if( !$that.hasClass('reset-cat-js') ){
		$that.toggleClass(active);
		$('.reset-cat-js').removeClass(active);
	}else{
		$('.comment-cat-js').removeClass(active);
		$that.addClass(active);
	}

	const commentCat = getActiveCommentCats();
	const sentimentCat = $('.sentiment-cat-js.active').data('comment-cat');
	const casinoId = $('.comment-cat-wrapper-js').data('casino-id');
	console.log(commentCat);
	console.log(sentimentCat);
	$.ajax({
		type: 'POST',
		url: commentsScript.url,
		data:{
			action: commentsScript.action,
			nonce: commentsScript._wp_nonce,
			post_id: casinoId,
			sentiment_category: sentimentCat,
			comment_category: commentCat
		},
		success: function (result) {
			if(result === ''){
				result = '<p class="mt-4">Nothing was found by your parameters. You can try other filter options or share your unique experience below</p>';
			}
			$('.comments-wrapper-js').html(result);
		}
	});

});

function getActiveCommentCats() {
	const activeCommentCats = [];
	$('.comment-cat-js.active').each(function() {
		activeCommentCats.push($(this).data('comment-cat'));
	});
	return activeCommentCats.join(',');
}

$('.sentiment-cat-js').on('click',function(){
	$('#comments_pagination').remove();
	$('.sentiment-cat-js').removeClass('active');
	const $that = $(this);
	$that.addClass('active');
	const sentimentCat = $that.data('comment-cat');
	const casinoId = $('.comment-cat-wrapper-js').data('casino-id');
	const commentCat = getActiveCommentCats();
	$.ajax({
		type: 'POST',
		url: commentsScript.url,
		data:{
			action: commentsScript.action,
			nonce: commentsScript._wp_nonce,
			post_id: casinoId,
			sentiment_category: sentimentCat,
			comment_category: commentCat,
		},
		success: function (result) {
			if(result === ''){
				result = '<p class="mt-4">Nothing was found by your parameters. You can try other filter options or share your unique experience below</p>';
			}
			$('.comments-wrapper-js').html(result);
		}
	});

});
