import bootReadCookie from "./ReadCookies";
import bootSetCookie from "./SetCookies";
import DOMPurify from 'dompurify';
async function hasClicked(data){
	const userClicked = await bootReadCookie('user_voted_bonus_'+ data);
	return !!userClicked;

}

$('.js-popup-close').on('click',async function(){
	$('.voting-popup').addClass('hidden');
	$('.active-form-bonus-feedback').trigger( "submit" );
});
$('.voting-popup-js').click(function (event) {
	if (!$(event.target).closest(".bonus-vote").length) {
		$(".js-bonus-feedback-reason-inputs").addClass('hidden');
		$('.voting-popup').addClass('hidden');
		$('.active-form-bonus-feedback').trigger( "submit" );
	}
});



$('.js-vote-not').on('click',async function(){
	const $that = $(this);
	const $form = $('.bonus-vote');
	const id = DOMPurify.sanitize($that.data('id'));
	$form[0].reset();
	$form.data('vote-item-id', id);
	if( ! await hasClicked(id) ){
		await open_vote_popup($that, 'dislike');
		$form.addClass('active-form-bonus-feedback');
	}else{
		$that.css({'cursor':'not-allowed'});
	}
});

$('.js-vote-yes').on('click',async function(){
	const $that = $(this);
	const id = DOMPurify.sanitize($that.data('id'));
	const $form = $('.bonus-vote');
	$form[0].reset();
	$form.data('vote-item-id', id);
	if( ! await hasClicked(id) ){
		await open_vote_popup($that, 'like');
		$form.addClass('active-form-bonus-feedback');
	}else{
		$that.css({'cursor':'not-allowed'});
	}
});

async function open_vote_popup($that,vote){
	$('.thank-msg-js').addClass('hidden');
	$('.voting-popup').removeClass('hidden');
	const $form = $('.bonus-vote');
	$form.removeClass('active-form-bonus-feedback');
	$('.js-bonus-feedback-reason-inputs').addClass('hidden');
	$('.js-bonus-feedback-reason-inputs .js-scenario').addClass('hidden');
	$form.find('.js-bonus-feedback-reason-inputs').removeClass('hidden');
	$form.find('.js-bonus-feedback-reason-inputs .js-'+vote+'-scenario').removeClass('hidden');
	$form.find('input[name="user_feedback"]').val(vote);
}

/**
 * @var bonusVotesScript
 */
$('.bonus-vote').on('submit',async function(e){
	e.preventDefault();
	const $that = $(this);
	const voteItemId = DOMPurify.sanitize($that.data('vote-item-id'));
	const userFeedback = DOMPurify.sanitize($that.find('input[name="user_feedback"]').val());
	const formData = new FormData($that[0]);
	const serializedFormData = {};
	formData.forEach((value, key) => {
		serializedFormData[key] = value;
	});
	const dislikeVoteNumber = DOMPurify.sanitize($that.find('.js-vote-not').data('vote-number'));
	const likeVoteNumber = DOMPurify.sanitize($that.find('.js-vote-yes').data('vote-number'));

	if( ! await hasClicked(voteItemId) ){
		$.ajax({
			type: 'POST',
			url: bonusVotesScript.url,
			data:{
				action: bonusVotesScript.action,
				nonce: bonusVotesScript._wp_nonce,
				post_id: voteItemId,
				vote_value: userFeedback,
				current_url: window.location.href,
				like_number: likeVoteNumber,
				dislike_number: dislikeVoteNumber,
				data_form: serializedFormData
			},
			success: function (result) {
				bootSetCookie('user_voted_bonus_'+ voteItemId,'voted',10);
				$('.js-bonus-feedback-reason-inputs').addClass('hidden');
				$('.js-bonus-feedback-reason-inputs .js-scenario').addClass('hidden');
				$('.thank-msg-js').removeClass('hidden');
				$that.removeClass('active-form-bonus-feedback');
				$that[0].reset();

			}
		});
	}

});
