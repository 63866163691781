class CountrySelect {
	constructor(selector) {
		this.$countrySelectForm = $(selector);
		this.countrySearchInput = $('#country_search');
		this.currencySearchInput = $('#currency_search');
		this.countryListDropdown = $('#country_list');
		this.currencyListDropdown = $('#currency_list');
		this.countrySelect = $('#country_select');
		this.currencySelect = $('#currency_select');
		this.init();
	}

	init() {
		if (!this.$countrySelectForm.length) {
			return;
		}
		this.countrySearchInput.on('input', () => this.filterCountries());
		this.countrySearchInput.on('click', () => this.toggleCountries());
		this.currencySearchInput.on('input', () => this.filterCurrencies());
		this.currencySearchInput.on('click', () => this.toggleCurrencies());
		this.countryListDropdown.on('click', 'div', (e) => this.selectCountry(e));
		this.currencyListDropdown.on('click', 'div', (e) => this.selectCurrency(e));
		this.$countrySelectForm.on('submit', (e) => this.handleCountrySelect(e));
	}

	toggleCountries() {
		if (this.countryListDropdown.hasClass('hidden')) {
			this.showCountries();
		} else {
			this.hideCountries();
		}
	}
	toggleCurrencies() {
		if (this.currencyListDropdown.hasClass('hidden')) {
			this.showCurrencies();
		} else {
			this.hideCurrencies();
		}
	}
	hideCountries() {
		this.countryListDropdown.addClass('hidden');
	}
	hideCurrencies() {
		this.currencyListDropdown.addClass('hidden');
	}

	showCountries(){
		const countries = this.countrySelect.children('option');
		this.countryListDropdown.empty();
		countries.each((index, country) => {
			this.countryListDropdown.append(`<div class="hover:bg-slate-200 cursor-pointer" data-value="${$(country).val()}">${$(country).text()}</div>`);
		});
		this.countryListDropdown.removeClass('hidden');
	}

	showCurrencies(){
		const currencies = this.currencySelect.children('option');
		this.currencyListDropdown.empty();
		currencies.each((index, cur) => {
			this.currencyListDropdown.append(`<div class="hover:bg-slate-200 cursor-pointer" data-value="${$(cur).val()}">${$(cur).text()}</div>`);
		});
		this.currencyListDropdown.removeClass('hidden');
	}
	filterCountries() {
		const searchTerm = this.countrySearchInput.val().toLowerCase();
		const countries = this.countrySelect.children('option');
		this.countryListDropdown.empty();

		countries.each((index, country) => {
			const countryName = $(country).text().toLowerCase();
			if (countryName.includes(searchTerm)) {
				this.countryListDropdown.append(`<div class="hover:bg-slate-200 cursor-pointer" data-value="${$(country).val()}">${$(country).text()}</div>`);
			}
		});

		if (searchTerm) {
			this.countryListDropdown.removeClass('hidden');
		} else {
			this.countryListDropdown.addClass('hidden');
		}
	}
	filterCurrencies() {
		const searchCurrency = this.currencySearchInput.val().toLowerCase();
		const currencies = this.currencySelect.children('option');
		this.currencyListDropdown.empty();

		currencies.each((index, cur) => {
			const currencyName = $(cur).text().toLowerCase();
			if (currencyName.includes(searchCurrency)) {
				this.currencyListDropdown.append(`<div class="hover:bg-slate-200 cursor-pointer" data-value="${$(cur).val()}">${$(cur).text()}</div>`);
			}
		});

		if (searchCurrency) {
			this.currencyListDropdown.removeClass('hidden');
		} else {
			this.currencyListDropdown.addClass('hidden');
		}
	}

	selectCountry(e) {
		const selectedCountry = $(e.target).data('value');
		this.countrySelect.val(selectedCountry);
		this.countrySearchInput.val($(e.target).text());
		this.countryListDropdown.addClass('hidden');
	}

	selectCurrency(e) {
		const selectedCurrency = $(e.target).data('value');
		this.currencySelect.val(selectedCurrency);
		this.currencySearchInput.val($(e.target).text());
		this.currencyListDropdown.addClass('hidden');
	}

	handleCountrySelect(e) {
		e.preventDefault();
		const selectedCountry = $('#country_select').val();
		const selectCurrency = $('#currency_select').val();
		const nonce = $('#country_select_nonce').val();
		this.updateCountry(selectedCountry,selectCurrency,nonce);
	}

	updateCountry(country,currency,nonce) {

		$.ajax({
			url: localizedScript.url,
			type: 'POST',
			data: {
				'action': 'country_select',
				'nonce': nonce,
				'country': country,
				'currency': currency
			},
			success: (response) => {
				console.log(response);
				location.reload();

			},
			error: (xhr, status, error) => {
				console.error(xhr);
			}
		});
	}
}

new CountrySelect('#country_select_form');
