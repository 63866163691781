import bootReadCookie from "./ReadCookies";
import bootSetCookie from "./SetCookies";

async function hasAdded(data){
	const userAdded = await bootReadCookie('product_'+ data);
	return !!userAdded;
}
export const bootAddToCard = () => {
	$('body').on('click','.add-to-cart-js', async function () {
		const productId = $(this).data('id');
		const cartUrl = $('.cart-count').attr('href');
		if (!await hasAdded(productId)) {
			$(this).addClass('added');
			$(this).text('Перейти до кошика');
			$(this).parent().addClass('product-added');
			const cartNumber = $('#cart_number');
			const cartTotal = $('#cart_total');
			const price = $(this).data('price');
			const actualCartCount = parseInt(cartNumber.text());
			const actualTotalPrice = parseInt(cartTotal.text());
			bootSetCookie('product_' + productId, 'added_' + price, 5);
			cartNumber.text(actualCartCount + 1);
			cartTotal.text(actualTotalPrice + parseInt(price));
		} else {
			location.assign(cartUrl);
		}
	});
}

export const bootRemoveFromCart = () =>{
	$('.remove').on('click',function(){
		const dataRemove = $(this).data('remove');
		const dataRemovedPrice = parseInt($(this).data('removed-price'));
		const $cartNumber = $('#cart_number');
		const $cartTotal = $('#cart_total');
		const cartNumber = $cartNumber.text();
		const cartTotal = $cartTotal.text();
		bootSetCookie('product_'+ dataRemove,"",-1);
		$(this).closest('.cart-item').remove();
		$cartNumber.text( parseInt(cartNumber) - 1);
		const cartSumUpdate = parseInt(cartTotal) - dataRemovedPrice;
		$('#cart_subtotal').text(cartSumUpdate);
		const $cartContent = $('#cart_content');
		$cartTotal.text( cartSumUpdate );
		if( cartSumUpdate === 0){
			$('#billing_wrapper').html('');
			const shopPageUrl = $cartContent.data('shop-url');
			$cartContent.html("<h3 class=\"text-2xl font-bold\">Ваш кошик порожній</h3><p>Для здійснення покупок, будь-ласка, перейдіть до сторінки <a class=\"text-brand hover:underline\" href='"+shopPageUrl+"'>ART-крамниця</a>.</p>");
		}
	});
}
