export const Gallery = () => {

	/**
	 *  ============= Gallery Slider ==================
	 * ************************************************
	 */
	var lastX,
	run,
	allItems = $('.g-gallery-item').length - 1;
	var gallerySlide = $('#gallery_slide');
	// Exit from gallery
	$('.g-exit-js').on('click',function(){
	$('#g_play').removeClass('disabled active').addClass('enabled');
	$('#g_stop').removeClass('disabled active');
	clearInterval(run);
	$('#gallery_popup_slider').fadeOut();
});

// Exit from gallery by click outside
$('#gallery_popup_slider').on('click',function(event){
	if (!$(event.target).closest('.gallery-navigation').length && !$(event.target).closest("#gallery_slide img").length) {
		$('#g_play').removeClass('disabled active').addClass('enabled');
		$('#g_stop').removeClass('disabled active');
		clearInterval(run);
		$("#gallery_popup_slider").fadeOut();
	}
});

// Prev and Next slide by touch move in mobiles
gallerySlide.bind('touchstart','img', function(e) {
	lastX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
});
gallerySlide.bind('touchmove','img', function(e) {
	var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
	if (Math.abs(currentX-lastX) < 30) { return; }
	if (currentX > lastX) {
		prevSlideAction();
	} else {
		nextSlideAction();
	}
});


// Previous slide event handler
$('#g_prev').on('click',prevSlideAction);

function prevSlideAction(){
	var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
	var prevSlide = getPrevSlide(activeSlide);
	$('.gallery-item-js').removeClass('active');
	var prevObj =  $('.g-gallery-item').eq(prevSlide);
	$(prevObj).addClass('active');
	generateSlideMarkup(prevObj);
}

function getPrevSlide(activeSlide){
	if(activeSlide > 0){
		return activeSlide-1;
	}
	if(activeSlide === 0){
		return allItems;
	}
}

// Next slide
$('#g_next').on('click',nextSlideAction);

function nextSlideAction(){
	var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
	var nextSlide = getNextSlide(activeSlide);
	$('.gallery-item-js').removeClass('active');
	var nextObj =  $('.g-gallery-item').eq(nextSlide);
	$(nextObj).addClass('active');
	generateSlideMarkup(nextObj);
}

function getNextSlide(activeSlide){
	if(activeSlide < allItems){
		return activeSlide+1;
	}
	if(activeSlide === allItems){
		return 0;
	}
}

$('.g-control').on('click',function(){
	$('.g-control').removeClass('active');
	$(this).addClass('active');
});

$('#g_play').on('click',function(){
	if( $(this).hasClass('enabled') ){
		run = setInterval(function(){ gallerySliderRun()},3000);
		$(this).removeClass('enabled').addClass('disabled');
	}
});

$('#g_stop').on('click',function(){
	$('#g_play').removeClass('disabled').addClass('enabled');
	clearInterval(run);
});

function gallerySliderRun(){
	if( !$('.gallery-items').find('#gallery-navigation').hasClass('stop') ){
		var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
		var nextSlide = getNextSlide(activeSlide);
		$('.gallery-item-js').removeClass('active');
		var nextObj =  $('.g-gallery-item').eq(nextSlide);
		$(nextObj).addClass('active');
		generateSlideMarkup(nextObj);
	}
}
$('#g_info').on('click',function(){
	$(this).toggleClass('active');
	$('#gallery_slide_info').toggle();
});

/**
 * Calling generateSlideMarkup on gallery item block click
 * ignoring <a> tag
 */
$('.gallery-item-js').on('click',function(e){
	var $target = $(e.target);
	var IGNORE_SELECTOR = 'a';
	if ( !$target.is(IGNORE_SELECTOR) ) {
		$('.gallery-item-js').removeClass('active');
		$(this).addClass('active');
		generateSlideMarkup(this);
	}
});

/**
 * Generating markup inside popup
 * @param obj
 */
function generateSlideMarkup(obj){
	var dataUrl = $(obj).data('ph-product-object-url');
	var dataSrc = $(obj).data('src-url');
	var dataPhTitle = $(obj).data('ph-title');
	var dataPhDescription = $(obj).data('ph-description');
	var dataLikes = $(obj).data('likes');
	$('#gallery_popup_slider').fadeIn();
	$('#gallery_slide').html("<img src='"+dataSrc+"'" + " alt='slide' >");
	if ( dataUrl && dataPhTitle && dataPhDescription ){
		$('#gallery_slide_info').html('<h4><a href="'+dataUrl+'">'+dataPhTitle+'</a></h4>'+'<p><i class="fa fa-heart" aria-hidden="true"></i><span class="total-virtual-likes">'+dataLikes+'</span></p><p>'+dataPhDescription+'</p>');
	}
}

	function readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0)===' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}
	function hasClicked(data){
		var userClicked = readCookie('user_clicked_'+ data);
		return !!userClicked;

	}
	function setCookie(name,value,days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires = "; expires="+date.toGMTString();
		}
		else expires = '';
		document.cookie = name+"="+value+expires+"; path=/";
	}

	// --------- Gallery Likes clicks ------------------
	$('.js-like').on('click',function(){
		var currentItem = $('.gallery-item-js.active');
		var currentPostId = parseInt(currentItem.data('post-id'));
		var activeSlide = parseInt(currentItem.data('order'));
		var srcUrl = currentItem.data('src-url');
		var currentLikes = parseInt(currentItem.data('likes'));
		if( !hasClicked(srcUrl) ){
			$.ajax({
				type: 'POST',
				url: likesScript.url,
				data:{
					action: likesScript.action,
					nonce: likesScript._wp_nonce,
					post_id: currentPostId,
					active_slide: activeSlide,
					current_likes: currentLikes
				},
				success: function (res) {
					console.log(res);
					if( res.success === true){
						$('.total-virtual-likes').text(currentLikes+1);
						currentItem.find('.likes-total').text(currentLikes+1);
						setCookie('user_clicked_'+ srcUrl,'clicked',5);
					}else{
						console.log(res);
					}
				}
			});
		}else{
			console.log('You have already clicked this photo');
		}

	});

}
