const agree = () => {
	return localStorage.getItem('agree') === 'y';
};

const markUserSeenCookie = () => {
	localStorage.setItem('agree', 'y');
};
export default async function bootCookies() {
	window.addEventListener('load', () => {
		showCookiesPopup();
	});
}

async function showCookiesPopup() {
	const $cookies = $('#warning_popup');
	const $cookiesAccept = $('#warning_popup_close');

	if (!agree()) {
		setTimeout(function () {
			$cookies.fadeIn(200).fadeTo(400, 1);
		}, 1000);

		$cookiesAccept.click(function () {
			$cookies.fadeOut(200).fadeTo(400, 1);

			markUserSeenCookie();
		});
	} else {
		$cookies.hide();
	}
}
