
export const bootCartFormHandler = () => {
	$('#carts_total_form').on('submit',function(e){
		e.preventDefault();
		const checkoutUrl = $(this).data('checkout-url');
		const checkoutMethod = $(this).find("input[name='checkout_method']:checked").val();
		$.ajax({
			type: 'POST',
			url: cartForm.url,
			data:{
				action: cartForm.action,
				nonce: cartForm._wp_nonce,
				checkout_method: checkoutMethod,
			},
			success: function (res) {
				console.log(res);
				if(res.success === true){
					location.assign(checkoutUrl);
				}
			},
			error: function(jqxhr, status, exception) {
				console.log(exception);
			}
		});
	});

}
