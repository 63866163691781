
class CasinoTabs {
	constructor() {
		this.tabs = document.querySelectorAll('.casino-countries-tab-js');
		this.tabContents = document.querySelectorAll('.tab-content');

		this.setupEventListeners();
	}

	setupEventListeners() {
		this.tabs.forEach(tab => {
			tab.addEventListener('click', () => this.handleTabClick(tab));
		});
	}

	handleTabClick(clickedTab) {
		this.tabs.forEach(tab => {
			tab.classList.remove('active');
		});

		this.tabContents.forEach(content => {
			content.classList.remove('active');
		});

		clickedTab.classList.add('active');

		const tabId = clickedTab.id;
		const tabContent = document.getElementById('tab_' + tabId + '_content');

		if (tabContent) {
			tabContent.classList.add('active');
		}
	}
}

// Instantiate the object
const casinoTabs = new CasinoTabs();
