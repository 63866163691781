/**
 * @var loadComments
 * @constructor
 */
export const LoadCommentsHandler = () =>{
	$('.load-comments-js').on('click',function(e){
		e.preventDefault();
		const currentId = $(this).data('current-id');
		$.ajax({
			method: 'POST',
			url: loadComments.url,
			data: {
				nonce: loadComments._wp_nonce,
				action: loadComments.action,
				id: currentId
			},

			success: function( data ) {
				$( '#comments_wrapper' ).html( data );
				$('#load_comments').hide();
			}
		});
	});

	/**
	 * Displaying comment by click
	 */
	const $body = $('body');
	$body.on('click','.show-comments-js',function(){
		$('#comments').slideToggle();
		$(this).find('i').toggleClass("fa-caret-up fa-caret-down");
		$(this).find('.show-comments-text-js').toggleText('Приховати коментарі','Показати коментарі');
	});
	$.fn.extend({
		toggleText: function(stateOne, stateTwo) {
			return this.each(function() {
				stateTwo = stateTwo || '';
				$(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
					: $(this).text(stateOne);
			});
		}
	});

}
import {checker} from './Checker';
import DOMPurify from 'dompurify';
export const SendCommentHandler = () => {
	const $commentsForm = $('#comments_form');
	$commentsForm.on('submit',function(e){
		e.preventDefault();
		const formData = new FormData($(this).get(0));
		const sanitizedData = {};
		const check = {};
		const $result = $('#result_message');

		for (let [key, value] of formData.entries()) {
			sanitizedData[key] = DOMPurify.sanitize(value);
		}
		for (let [key, value] of formData.entries()) {
			check[key] = ( checker.validateField(key,value));
		}
		const hasErrors = Object.values(check).some((value) => value === false);

		if( hasErrors ){
			console.log('Validation not passed');
			console.log(sanitizedData);
			return;
		}
		$.ajax({
			method: 'POST',
			url: loadComments.url,
			data: {
				nonce: loadComments._wp_nonce,
				action: loadComments.action,
				fields: sanitizedData
			},

			success: function( res ) {
				console.log(res);
				$result.text('Дякуюємо! Коментар буде опубліковано після модерації');
				setTimeout(function(){
					$result.text('');
					$commentsForm[0].reset();
				},5000);

			}
		});
	});
}

export const handleRating = () =>{
	/**
	 * Comments rating
	 */
	const $rating = $("input[name='rating']");
	$rating.on('change',"",function(){
		const rating = parseInt($(this).val());
		$('.star-keeper-js').each(function(i){
			if( i+1 <= rating ){
				$(this).find('i').removeClass('fa-star-o').addClass('fa-star');
			}else{
				$(this).find('i').removeClass('fa-star').addClass('fa-star-o');
			}
		})
	});
}
