import DOMPurify from 'dompurify';
import {checker} from './Checker';
import bootDeleteCookie from "../DeleteCookies";

export const bootCheckoutFormHandler = () => {

	checker.phoneMask($('#client_phone'));
	const $form = $('#checkout_form');
	$form.on('submit',function(e){
		e.preventDefault();
		const formData = new FormData($(this).get(0));
		const sanitizedData = {};
		const check = {};

		for (let [key, value] of formData.entries()) {
			sanitizedData[key] = DOMPurify.sanitize(value);
		}
		for (let [key, value] of formData.entries()) {
			check[key] = ( checker.validateField(key,value));
		}
		const hasErrors = Object.values(check).some((value) => value === false);

		if( hasErrors ){
			return;
		}

		$.ajax({
			url: checkoutForm.url,
			method: 'POST',
			data: {
				action: checkoutForm.action,
				nonce: checkoutForm._wp_nonce,
				fields: sanitizedData
			},
			success: function(response) {
				console.log(response);
				// Removing products from cookies
				const dataRemove = sanitizedData.products_id;
				const arrRemove = dataRemove.split(',');
				let x;
				for ( x in arrRemove ){
					if(arrRemove.hasOwnProperty(x)){
						bootDeleteCookie('product_'+ arrRemove[x]);
					}
				}
				$form[0].reset();
				$('#checkout_thank_msg').fadeIn().css({'display':'flex'});
				setTimeout(function(){
					window.location.replace('/art-shop');
				}, 10000);

			},
			error: function(jqxhr, status, exception) {
				console.log(exception);
			}
		});

	});

}
