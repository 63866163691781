import DOMPurify from 'dompurify';
function testEmail(field){
	const emailPattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
	return emailPattern.test(field);
}

/**
 * @var writeReviewScript
 */
$('#customer_review_form').on('submit',function(e){
    e.preventDefault();
    const $that = $(this);
	const $email = $that.find("#user_email");
	const $name = $that.find("#user_name");
    const userEmail = DOMPurify.sanitize($email.val());
    const check_mail = testEmail(userEmail);
    const userName = DOMPurify.sanitize($name.val());
    const userRating = DOMPurify.sanitize($that.find("#user_rating").val());
    const userPros = DOMPurify.sanitize($that.find("#pros").val());
    const userCons = DOMPurify.sanitize($that.find("#cons").val());
	const userAgreement = $that.find('#agreement').is(":checked");
    const id = DOMPurify.sanitize($that.find("#casino_id").val());
    const ip = DOMPurify.sanitize($that.find("#user_ip").val());
	const $userNameErr = $('#user_name_error');
	const $userEmailErr = $('#user_email_error');

	if(userName === '' ){
		$name.addClass('error-input');
		$userNameErr.text($userNameErr.data('message'));
	}else{
		$name.removeClass('error-input');
		$userNameErr.text('');
	}
    if(userEmail === '' ){
		$email.addClass('error-input');
		$userEmailErr.text($userEmailErr.data('message'));
    }else if( check_mail !== true ){
		$email.addClass('error-input');
		$userEmailErr.text($userEmailErr.data('message'));
    }else{
		$email.removeClass('error-input');
		$userEmailErr.text('');
    }
	let response = '';

	if( $('.g-recaptcha-js').length ){
		response = grecaptcha.getResponse();
	}
    if( check_mail === true && userName !== '' ){
        $.ajax({
            type: 'POST',
            url: writeReviewScript.url,
            data:{
                action: writeReviewScript.action,
                nonce: writeReviewScript._wp_nonce,
                user_name: userName,
                user_email: userEmail,
				user_rating: userRating,
                pros: userPros,
				cons: userCons,
				agreement: userAgreement,
				id: id,
				ip: ip,
                captcha: response
            },
            success: function (res) {
                if(res.success === true){
					$('#range_tooltip').text('').hide();
                    $that[0].reset();
					if( response ){
						grecaptcha.reset();
					}
                    $('.thank-popup-wrapper').fadeIn();
                    setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 5000);
                } else if(res.success === false && res.data.error === 'recaptcha validation failed'){
					if( response ){
						grecaptcha.reset();
						const $recaptcha = $('.recaptcha-error-msg');
						const errMsg = $recaptcha.data('msg');
						$recaptcha.text(errMsg);
					}

                    setTimeout(function(){$recaptcha.text('');}, 5000);
                }else if( res.success === false && res.data.error === 'user_has_sent_feedback'){
					if( response ){
						grecaptcha.reset();
					}
					$userEmailErr.text(res.data.error_msg);
				}
            },
            error: function(jqxhr, status, exception) {
                console.log(exception);
            }
        });
    }
});
