import DOMPurify from 'dompurify';
class LoadMoreComments {
	constructor() {
		this.body = $('body');
		this.loadMoreBtn = $('#show_more_comments');
		this.init();
	}

	init() {
		if (!this.loadMoreBtn) {
			return;
		}
		this.offset = 5;
		this.body.on('click','#show_more_comments', (e) => this.handleLoadMoreComments(e));
		this.body.on('click','.comment-cat-js', () => this.clearOffset());
		this.body.on('click','.sentiment-cat-js', () => this.clearOffset());
	}

	clearOffset(){
		this.offset = 5;
	}

	handleLoadMoreComments(e) {
		e.preventDefault();
		e.stopPropagation();

		const showMore = $('body #show_more_comments');
		const nonce = showMore.data('nonce');
		const $activeCommentBtn = $('body .comment-cat-js.active');
		const $activeSentimentBtn = $('body .sentiment-cat-js.active');
		const commentCategory = DOMPurify.sanitize($activeCommentBtn.data('comment-cat'));
		const sentimentCategory = DOMPurify.sanitize($activeSentimentBtn.data('comment-cat'));
		const casinoId = DOMPurify.sanitize($activeCommentBtn.data('casino-id'));
		const data = {
			'action': 'load_more_comments',
			'nonce': nonce,
			'sentiment_category': sentimentCategory,
			'comment_category':commentCategory,
			'casino_id':casinoId,
			'offset': this.offset
		};
		console.log(data);
		$.ajax({
			url: localizedScript.url,
			type: 'POST',
			data: data,
			success: (response) => {

				$(showMore).remove();
				this.offset += 5;
				document.querySelector('.comments-wrapper-js').insertAdjacentHTML('beforeend', response);
			}
		});
	}
}

new LoadMoreComments();
