class PaginateComments {
	constructor() {
		this.body = $('body');
		this.paginationWrapper = $('body #comments_pagination');
		this.init();
	}

	init() {
		if (!this.paginationWrapper ) {
			return;
		}
		this.body.on('click','.comments-pagination-js', (e) => this.handlePaginateComments(e));
		this.body.on('click','.comment-cat-js', () => this.clearOffset());
		this.body.on('click','.sentiment-cat-js', () => this.clearOffset());
	}

	clearOffset(){
		this.paginationWrapper.data('current-page', 1);
	}

	getActiveCommentCats() {
		const activeCommentCats = [];
		$('.comment-cat-js.active').each(function() {
			activeCommentCats.push($(this).data('comment-cat'));
		});
		return activeCommentCats.join(',');
	}

	handlePaginateComments(e) {
		e.preventDefault();
		e.stopPropagation();

		const paginationTotalWrapper = $('body .pagination-wrapper-js');
		const paginationWrapper = $('body #comments_pagination');
		let page = $(e.target).data('page');
		const currentPage = parseInt(paginationWrapper.data('current-page'), 10) || 1;
		const totalCommentsCount = paginationWrapper.data('items-count');
		const maxDisplayed = paginationWrapper.data('max-displayed');
		const totalPages = Math.ceil(totalCommentsCount / maxDisplayed);
		const nonce = paginationWrapper.data('nonce');

		const $activeSentimentBtn = $('body .sentiment-cat-js.active');
		const commentCategory = this.getActiveCommentCats() ? this.getActiveCommentCats() : 'all';
		const sentimentCategory = $activeSentimentBtn.data('comment-cat');
		const casinoId = $('.comment-cat-wrapper-js').data('casino-id');

		if (page === 'prev') {
			page = Math.max(currentPage - 1, 1);
		} else if (page === 'next') {
			page = Math.min(currentPage + 1, totalPages);
		}


		const offset = (page - 1) * maxDisplayed;
		const data = {
			'action': 'paginate_comments',
			'nonce': nonce,
			'page':page,
			'sentiment_category': sentimentCategory,
			'comment_category':commentCategory,
			'casino_id':casinoId,
			'offset': offset,
			'max_displayed':maxDisplayed
		};
		console.log(data);
		$.ajax({
			url: localizedScript.url,
			type: 'POST',
			data: data,
			success: (response) => {
				paginationWrapper.data('current-page', page);
				$('.comments-wrapper').remove();
				paginationTotalWrapper.before(response);
				this.updatePaginationButtons(page, totalPages, totalCommentsCount, maxDisplayed);
			}
		});
	}

	updatePaginationButtons(currentPage, totalPages, totalCommentsCount, maxDisplayed) {
		console.log(currentPage, totalPages, totalCommentsCount, maxDisplayed);
		const from = (currentPage * maxDisplayed) - maxDisplayed + 1;
		let to = currentPage * maxDisplayed;
		if( currentPage === totalPages ){
			to = totalCommentsCount;
		}

		let buttonsHtml = '<button class="comments-pagination-prev comments-pagination-js" data-page="prev">&lt;</button>';

		if (totalPages <= 4) {
			for (let i = 1; i <= totalPages; i++) {
				buttonsHtml += this.getPaginationButtonHtml(i, currentPage);
			}
			console.log(buttonsHtml);
		} else {
			buttonsHtml += this.getPaginationButtonHtml(1, currentPage);
			buttonsHtml += this.getPaginationButtonHtml(2, currentPage);


			if (currentPage > 4) {
				buttonsHtml += '<span class="comments-pagination-ellipsis">...</span>';
			}
			const start = Math.max(3, currentPage - 1);
			const end = Math.min(totalPages - 2, currentPage + 1);

			for (let i = start; i <= end; i++) {
				buttonsHtml += this.getPaginationButtonHtml(i, currentPage);
			}

			if (currentPage < totalPages - 3) {
				buttonsHtml += '<span class="comments-pagination-ellipsis">...</span>';
			}

			buttonsHtml += this.getPaginationButtonHtml(totalPages - 1, currentPage);
			buttonsHtml += this.getPaginationButtonHtml(totalPages, currentPage);

		}

		buttonsHtml += '<button class="comments-pagination-next comments-pagination-js" data-page="next">&gt;</button>';
		const $body = $('body');
		$body.find('#comments_pagination').html(buttonsHtml);
		$body.find('#showing_comments_statistic').html('Showing ' + from + ' to ' + to + ' of ' + totalCommentsCount + 'results');

	}

	getPaginationButtonHtml(page, currentPage) {
		return `<button class="comments-pagination comments-pagination-js${page === currentPage ? ' active' : ''}" data-page="${page}">${page}</button>`;
	}



}

new PaginateComments();
