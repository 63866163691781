import DOMPurify from 'dompurify';
class Checker{

	/**
	 * Check tel input field
	 * @param number
	 * @param $input
	 * @param $err
	 * @returns {boolean}
	 */
	checkPhone(number,$input,$err){
		number = DOMPurify.sanitize(number);
		const phone_pattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		const check_phone = phone_pattern.test(number);
		if( number !== '' && check_phone !== true ){
			$input.addClass('error');
			$err.text('Не валідний номер');
			return false;
		}
		else if (number === ''){
			$input.addClass('error');
			$err.text('Поле телефон порожнє');
			return false;
		}
		else{
			$input.removeClass('error');
			$err.text('');
			return true;
		}
	}

	/**
	 * Check email field
	 * @param email
	 * @param $input
	 * @param $err
	 * @returns {boolean}
	 */
	checkEmail(email,$input,$err){
		email = DOMPurify.sanitize(email);
		const mail_pattern = /[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
		const check_mail = mail_pattern.test(email);
		if( email !== '' && check_mail !== true ){
			$input.addClass('error');
			$err.text('Не валідний email');
			return false;
		}
		else if ( email === ''){
			$input.addClass('error');
			$err.text('Поле email порожнє');
			return false;
		}
		else{
			$input.removeClass('error');
			$err.text('');
			return true;
		}
	}


	/**
	 *
	 * @param field
	 * @param $input
	 * @param $err
	 * @returns {boolean}
	 */
	checkTextField(field,$input,$err){
		field = DOMPurify.sanitize(field);
		if( field === '' ){
			$input.addClass('error');
			$err.text('Не заповнене поле');
			return false;
		}else{
			$input.removeClass('error');
			$err.text('');
			return true;
		}
	}
	checkNumberField(field, $input, $err) {
		field = DOMPurify.sanitize(field);

		// Check if the field is a number between 0 and 5
		if (field === '' || isNaN(field) || field < 0 || field > 5) {
			$input.addClass('error');
			$err.text('Поле повинно містити число від 0 до 5');
			return false;
		} else {
			$input.removeClass('error');
			$err.text('');
			return true;
		}
	}

	checkIdField(field, $input, $err) {
		field = DOMPurify.sanitize(field);
		// Check if the field is a valid non-negative integer (ID)
		if (field === '' || isNaN(field) || !Number.isInteger(+field) || field < 0) {
			return false;
		} else {
			return true;
		}
	}

	validateField (key, value){

		if( key === 'client_phone'){
			return this.checkPhone(value, $(`#${key}`), $(`#${key}_err`));
		}
		if (key === 'client_name' || key === 'author' || key === 'comment') {
			return this.checkTextField(value, $(`#${key}`), $(`#${key}_err`));
		}
		if (key === 'client_last_name') {
			return this.checkTextField(value, $(`#${key}`), $(`#${key}_err`));
		}
		if(key === 'rating'){
			//return this.checkNumberField(value, $(`#${key}`), $(`#${key}_err`));
		}
		if(key === 'comment_post_ID'){
			return this.checkIdField(value, $(`#${key}`), $(`#${key}_err`));
		}
		if(key === 'email'){
			return this.checkEmail(value, $(`#${key}`), $(`#${key}_err`))
		}
		return true;

	};

	phoneMask($tel){
		$tel.on('input', function (e) {
			const x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/);
			e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
		});
	}


}

export const checker = new Checker();

